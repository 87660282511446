<template>
  <div class="grid grid-cols-12 gap-4">
    <CodTraffic />
    <CodPrepaid />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue";
const CodTraffic = defineAsyncComponent(() => import("@/components/cod-traffic/Main.vue"));
const CodPrepaid = defineAsyncComponent(() => import("@/components/cod-prepaid/Main.vue"));

export default defineComponent({
  components: {
    CodPrepaid,
    CodTraffic,
  },
});
</script>
