
import { defineAsyncComponent, defineComponent } from "vue";
const CodTraffic = defineAsyncComponent(() => import("@/components/cod-traffic/Main.vue"));
const CodPrepaid = defineAsyncComponent(() => import("@/components/cod-prepaid/Main.vue"));

export default defineComponent({
  components: {
    CodPrepaid,
    CodTraffic,
  },
});
